import React from 'react';
import { useState } from 'react';
import WS from '/Users/fredjmoussa/fredjmoussa/src/WanderingStudio.jpeg';
import Sikilya from '/Users/fredjmoussa/fredjmoussa/src/Sikilya.jpeg';
import Mirage from '/Users/fredjmoussa/fredjmoussa/src/StillMirage.jpeg';
import TM from '/Users/fredjmoussa/fredjmoussa/src/StillTheMascot.jpeg';
import SN from '/Users/fredjmoussa/fredjmoussa/src/StillSolarNoon.jpeg';
import LSO from '/Users/fredjmoussa/fredjmoussa/src/LifeStyleOf.jpeg';
import BPAF from '/Users/fredjmoussa/fredjmoussa/src/videogame.png';


const Films = () => {
  // Initialisation de l'état pour gérer la visibilité des textes, un seul titre visible à la fois
  const [activeTitle, setActiveTitle] = useState(null);

  // Fonction pour gérer le clic sur un titre
  const handleTitleClick = (title) => {
    // Si le même titre est cliqué, on le referme
    if (activeTitle === title) {
      setActiveTitle(null);
    } else {
      // Sinon, on affiche le nouveau titre cliqué
      setActiveTitle(title);
    }
  };

  return (
    <div className='Films'>
    {/* titre cliquable */}
    <h2 onClick={() => handleTitleClick('title1')} style={{ cursor: 'pointer' }}>
    WANDERING STUDIO (WORK IN PROGRESS)
    </h2>
    {activeTitle === 'title1' && (
              <>
              <img src={WS} alt="WANDERING STUDIO"></img>
              <p>WANDERING STUDIO</p>      <p>W/ Corentin Laplanche-Tsutsui (WORK IN PROGRESS)</p><p>
          A mobile studio travels from east to west in Tunisia, from the desert to the industrial coast. Various protagonists, both local and visiting, embody scenes from an Arab-futuristic science fiction. Others tell the story of Nefta and Tataouine, as well as of the imaginary Mos Espa and Tatooine in Star Wars; of territorial or galactic frontiers.
        </p></>
    )}

    {/* titre cliquable */}
    <h2 onClick={() => handleTitleClick('title2')} style={{ cursor: 'pointer' }}>
    BETWEEN PAST AND FUTURE (WORK IN PROGRESS)
    </h2>
    {activeTitle === 'title2' && (
              <>
              <img src={BPAF} alt="BETWEEN PAST AND FUTURE "></img>
              <p>BETWEEN PAST AND FUTURE</p>      <p>(WORK IN PROGRESS)</p><p>
              Filmed within a fictional educational video game, Between Past and Future is set both in Tunisia and on an extraterrestrial colony, where the philosophical challenges of space exploration are recontextualized through the lens of Tunisia's colonial past.        </p></>
    )}

    {/* titre cliquable */}
    <h2 onClick={() => handleTitleClick('title3')} style={{ cursor: 'pointer' }}>
    SIKILYA (WORK IN PROGRESS)
    </h2>
    {activeTitle === 'title3' && (
<>               <img src={Sikilya} alt="SIKILYA"></img>

      <p>SIKILYA</p>
      <p>(WORK IN PROGRESS)</p>
      <p>
          The story takes place a thousand years ago on an island, the Emirate of Sicily, or more commonly Sikilya. The film, composed of wearable sculptures, proposes an exploration between the reality of the island and the story, weaving links between personal life and universal tales. The Saracens become the driving force behind the plot, while the tale acts as a catalyst. Sikilya arouses his interest as a liminal territory, one where the distant and the near, the past and the present, meet.
          </p><div className='link'><a href="https://www.villamedici.it/en/residenze-brevi/fredj-moussa-coming-soon/" target="blank">https://www.villamedici.it/en/residenze-brevi/fredj-moussa-coming-soon/</a></div></>
   )}

    {/* titre cliquable */}
    <h2 onClick={() => handleTitleClick('title4')} style={{ cursor: 'pointer' }}>
    MIRAGE, THE INNER SEA (2024)
    </h2>
    {activeTitle === 'title4' && (
      <>
                    <img src={Mirage} alt="MIRAGE"></img>
<p>MIRAGE, THE INNER SEA</p>
      <p>11min 16mm (2024)</p>
      <p>
          In 1875, a french colonial mission planned to flood the sahara under the waters of the mediterranean sea by digging a canal in southern tunisia.
        </p></>
    )}
    
        {/* titre cliquable */}
    <h2 onClick={() => handleTitleClick('title5')} style={{ cursor: 'pointer' }}>
    THE MASCOT (2023)
    </h2>
    {activeTitle === 'title5' && (
      <>
                    <img src={TM} alt="THE MASCOT"></img>
<p>THE MASCOT</p>
      <p>11min VR (2023)</p>        
      <p>
          Labib is a mascot created in 1992 and used for twenty years as an environmental mascot in Tunisia. However, associated with the Ben Ali dictatorship, it was removed from its functions by the new Minister of the Environment after the Arab Spring revolution. In 2022, its return was officially announced.
          </p><div className='link'><a href="https://archive.lefresnoy.net/en/Le-Fresnoy/production/2023/installation/1974/the-mascot" target="blank">https://archive.lefresnoy.net/en/Le-Fresnoy/production/2023/installation/1974/the-mascot</a></div></>
    )}

        {/* titre cliquable */}
        <h2 onClick={() => handleTitleClick('title6')} style={{ cursor: 'pointer' }}>
    SOLAR NOON (2022)
    </h2>
    {activeTitle === 'title6' && (
      <>              <img src={SN} alt="SOLAR NOON"></img>
      <p>SOLAR NOON</p>
      <p>11min 16mm (2022)</p>
      <p>
          In North Africa, between the end of the Atlas Mountains and the Saharan plateau, a dry lake separates the Mashreq (east) from the Maghreb (west). Against all expectations, it is a landscape that is constantly changing. To its physical mutability, the director adds further layers by inserting fragments of three stories from Herodotus' Histories, traditional narratives and his own text, presenting them to viewers in the form of tableaux vivants laden with symbols and rituals.
                </p><div className='link'><a href="https://www.ji-hlava.com/filmy/slunecni-poledne" target="blank">https://www.ji-hlava.com/filmy/slunecni-poledne</a></div></>

    )}

        {/* titre cliquable */}
        <h2 onClick={() => handleTitleClick('title7')} style={{ cursor: 'pointer' }}>
    LIFESTYLE OF (2021)
    </h2>
    {activeTitle === 'title7' && (
      <>              <img src={LSO} alt="LIFESTYLE OF"></img>
      <p> LIFESTYLE OF</p>
      <p>5min video (2021)</p>
        <p>
          In Fredj Moussa’s sound and video pieces, the flexibility of the oral form and the motion of images allows for the reinvention and molding of different stories. Working in collaboration with people he met by chance in Tunisia, the artist “heard fantastic stories that my logic rejects. This fictional world became for them a terrain of projection with infinite possibilities.”
        </p><div className='link'><a href="https://www.tigerstrikesasteroid.com/phl-/-free-for-all" target="blank">https://www.tigerstrikesasteroid.com/phl-/-free-for-all</a></div></>
    )}
</div>
  );
};


export default Films;