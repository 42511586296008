import React from 'react';

const About = () => {
  return (
    <div className="About">
      <p>Fredj Moussa (b. 1992) lives and works between France and Tunisia.
</p>
      <p>
      He graduated from the École nationale supérieure des Arts Décoratifs in Paris in 2018, and resided at the Cité internationale des arts in Paris in 2019. He studied at Le Fresnoy - Studio national des arts contemporains from 2021 to 2023.
</p>
      <p>
      In 2024, he is a resident at Villa Medici in Rome.
</p>
      <p>
      Fredj Moussa's work consists of films and sculptures used as costumes and sets. These pieces explore evolving landscapes. Weaving together reality and myth, each film unfolds as an immersive experience. Storytelling is a way of re-examining traditional narratives and offering new interpretations.
</p>
     <div className='exhibited'>
      <p>He has exhibited his work at Nuit Blanche in Paris (FRA), Loop Fair Barcelona (ESP), Tiger Strikes Asteroid Philadelphia (USA), Izmir Mediterranean Biennial (TUR), Cabane B in Bern (CHE), Les Brasseurs in Liège (BEL), Touching Faultlines in Crete (GRC) and UV – Fully Booked in Sousse (TUN) among others. Fredj Moussa's films have been selected for international festivals. He was a finalist for the Studio Collector prize at the Photo Days Festival at the MEP in Paris in 2022.
</p></div>
      <p>Fredj Moussa receives the CIFRA prize at Loop Fair Barcelona in 2023.
</p>
    </div>
  );
}

export default About;
