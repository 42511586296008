import React from 'react';

const Contact = () => {
  return (
    <div className="Contact">
      <p>info[at]fredjmoussa[dot]com</p>
    </div>
  );
}

export default Contact;
