// App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Films from './components/Films';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header>
        <div className="left">
            <Link to="/">Fredj 
            Moussa</Link>
          </div>
        <div className="right">
            <Link to="/about">About</Link>
          </div>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/films" element={<Films />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <footer>
          <div className="left">
            <Link to="/contact">Contact</Link>
          </div>
          <div className="right">
          <a href="https://www.instagram.com/fredjmoussa/" target="_blank" rel="noopener noreferrer">
    Follow
  </a>
        
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
